import {HttpClient, HttpResponse} from '@wix/http-client';
import {
  canInstallEcomPages,
  canMigrate as canMigrateEcomPages,
  getSiteRolloutStatus,
  markEcomCompatible,
  markMigratedSiteEcomCompatible,
} from '@wix/ambassador-bookings-v1-site-rollout-status/http';
import {
  CanMigrateResponse,
  GetSiteRolloutStatusResponse,
  MarkEcomCompatibleResponse,
  MarkMigratedSiteEcomCompatibleResponse,
} from '@wix/ambassador-bookings-v1-site-rollout-status/types';
import {ServicesCatalogServer} from '@wix/ambassador-services-catalog-server/http';
import {BusinessServer} from '@wix/ambassador-business-server/http';
import { UpdatePropertiesResponse, Property } from '@wix/ambassador-business-server/types';

export const BUSSINESS_READER_BASE_URL = '/_api/services-catalog-server';
export const BUSINESS_SERVER_BASE_URL = '/_api/business-server';

export class EditorScriptApi {
  constructor(private httpClient: HttpClient) {}

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  sleepUntil(condition: any) {
    return new Promise((resolve) => {
      const checkEditorReadyDone = () => {
        if (condition()) {
          resolve(true);
        } else {
          setTimeout(checkEditorReadyDone, 500);
        }
      };

      checkEditorReadyDone();
    });
  }

  async fetchWithRetry<T>(
    fetchCall: any,
    retries = 3,
    delay = 1000,
  ): Promise<T> {
    for (let i = 0; i < retries; i++) {
      try {
        await this.sleep(delay * i);
        return await fetchCall();
      } catch (e: any) {
        if (i == retries - 1) {
          throw e;
        }
      }
    }
  }

  async getIsBookingsOnEcom(): Promise<boolean> {
    try {
      const { data: siteRolloutStatusResponse } = await this.fetchWithRetry<
        HttpResponse<GetSiteRolloutStatusResponse>
      >(() =>
        this.httpClient.request<GetSiteRolloutStatusResponse>(
          getSiteRolloutStatus({}),
        ),
      );
      return siteRolloutStatusResponse.siteRolloutStatus.isBookingPlatformReady;
    } catch {
      return false;
    }
  }

  async getIsDataMigrationFailed(): Promise<boolean> {
    try {
      const { data: siteRolloutStatusResponse } = await this.fetchWithRetry<
        HttpResponse<GetSiteRolloutStatusResponse>
      >(() =>
        this.httpClient.request<GetSiteRolloutStatusResponse>(
          getSiteRolloutStatus({}),
        ),
      );
      return siteRolloutStatusResponse.siteRolloutStatus.isDataMigrationFailed;
    } catch {
      return false;
    }
  }

  async migrateMediaGallery() {
    await this.fetchWithRetry(() =>
      this.httpClient.post('/internal/services-server/media-migration', {
        maxRedirects: 10,
      }),
    );
  }

  async setEcomCompatible(): Promise<boolean> {
    try {
      const { data: siteRolloutStatusResponse } = await this.fetchWithRetry<
        HttpResponse<MarkEcomCompatibleResponse>
      >(() => this.httpClient.request(markEcomCompatible({})));

      return siteRolloutStatusResponse.siteRolloutStatus.isBookingPlatformReady;
    } catch {
      return false;
    }
  }

  async setMigratedSiteEcomCompatible(): Promise<boolean> {
    const { data: siteMigratedStatusResponse } = await this.fetchWithRetry<
      HttpResponse<MarkMigratedSiteEcomCompatibleResponse>
    >(() => this.httpClient.request(markMigratedSiteEcomCompatible({})));

    return siteMigratedStatusResponse.siteRolloutStatus.isBookingPlatformReady;
  }

  async canInstallEcom(): Promise<boolean> {
    try {
      const {
        data: { canInstall },
      }: any = await this.fetchWithRetry(() =>
        this.httpClient.request(canInstallEcomPages({})),
      );

      return canInstall;
    } catch {
      return false;
    }
  }

  async canMigrateEcom(): Promise<boolean> {
    try {
      const {
        data: { canMigrate },
      } = await this.fetchWithRetry<HttpResponse<CanMigrateResponse>>(() =>
        this.httpClient.request(canMigrateEcomPages({})),
      );

      return canMigrate;
    } catch {
      return false;
    }
  }

  async shouldMigrateEcom(
    checkCanMigrate: boolean,
  ): Promise<boolean> {
    try {
      if (checkCanMigrate) {
        const canMigrate = await this.canMigrateEcom();
        if (!canMigrate) {
          return false;
        }
      }
      return await this.canInstallEcom();
    } catch (e) {
      return false;
    }
  }
  async getBusinessCustomProperties( instance: any ): Promise<Property[]> {
    const businessInfoResponse = await ServicesCatalogServer(BUSSINESS_READER_BASE_URL, this.httpClient).BusinessCatalog()({Authorization: instance}).get({});

    return businessInfoResponse?.businessProperties?.customProperties;
  }

  async updateBusinessCustomProperties(instance: any, property: Property): Promise<UpdatePropertiesResponse> {
    const updatePropertiesRequest= {customProperties:[property]};
    return BusinessServer(BUSINESS_SERVER_BASE_URL, this.httpClient).Business()({Authorization: instance}).updateProperties(updatePropertiesRequest)
      .catch((e) => {
        throw new Error(
          `update properties failed. Received server error ${e} with response body ${e.response?.data?.message}`,
        );
      });
  }
}

