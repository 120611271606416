import { EditorScriptApi } from '../../api/api';
import { BasePageMigrator } from './base-page-migrator';
import {
  installEcomPlatform,
  addComponents,
  EcomComponent,
} from '@wix/ecom-platform-sdk';
import { ECOM_APP_DEF_ID } from '@wix/bookings-adapter-ooi-wix-sdk';
import { EcomStyleParams } from '@wix/ecom-platform-sdk/dist/es/src/types';
import { mapThankYouPageParams } from '../ecom-migration/style-transpiler';
import { HandleActionPayload, PageId } from '../../constants';
import { addBookingsPagesAsPanel } from '../pages-panel-actions';
import { isBookingsCheckoutInstalled, isEcomCheckoutInstalled, isEcomThankYouPageInstalled } from '../editor-sdk-actions';

export class EcomPagesMigrator extends BasePageMigrator {
  constructor(
    protected editorSDK,
    protected appToken,
    protected instance,
    protected handleActionPayload: HandleActionPayload,
    private editorScriptApi: EditorScriptApi,
  ) {
    super(editorSDK, appToken, instance, handleActionPayload);
  }

  public async shouldMigrate(): Promise<boolean> {
    const ecomCheckoutInstalled = await isEcomCheckoutInstalled(this.editorSDK);
    const ecomThankYouPageInstalled = await isEcomThankYouPageInstalled(this.editorSDK);
    const bookingsCheckoutInstalled = await isBookingsCheckoutInstalled(this.editorSDK);

    if (!bookingsCheckoutInstalled && ecomCheckoutInstalled && ecomThankYouPageInstalled) {
      await this.markAsEcom();
      return false;
    }

    return true;
  }

  public async execute() {
    const ecomCheckoutInstalled = await isEcomCheckoutInstalled(this.editorSDK);
    const ecomThankYouPageInstalled = await isEcomThankYouPageInstalled(this.editorSDK);

    await installEcomPlatform(this.editorSDK);

    if (!ecomCheckoutInstalled) {
      await addComponents(this.editorSDK, [
        EcomComponent.CHECKOUT,
      ]);
    }

    if (!ecomThankYouPageInstalled) {
      await addComponents(this.editorSDK, [
        EcomComponent.THANK_YOU_PAGE,
      ]);
      const checkoutParams = await this.getCheckoutParams();
      const newParams = await mapThankYouPageParams(checkoutParams);
      await this.setThankYouPageParams(newParams);
    }

    await this.removeCheckoutPage();

    await addBookingsPagesAsPanel(this.editorSDK, this.appToken);
    await this.markAsEcom();
  }

  private async getCheckoutParams() {
    let isBookingsCheckoutExists = await isBookingsCheckoutInstalled(this.editorSDK);

    if (!isBookingsCheckoutExists) {
      await this.addBookingsPage(PageId.BOOKINGS_CHECKOUT);
    }

    isBookingsCheckoutExists = await isBookingsCheckoutInstalled(this.editorSDK);

    if (!isBookingsCheckoutExists) {
      throw new Error('Bookings Checkout Page not exist - errorCode: 716a3830-7652-4215-8475-c7d512ed0cb0');
    }

    const siteStructure = await this.getSiteStructure();
    const checkoutStructure = this.findWidgetStructure(
      siteStructure,
      this.bookingCheckoutWidgetId,
    );
    const compRef = await this.editorSDK.document.components.getById(
      this.appToken,
      {
        id: checkoutStructure.id,
      },
    );
    return this.editorSDK.document.tpa.getStyleParams(this.appToken, {
      compRef,
    });
  }

  private async setThankYouPageParams(params: EcomStyleParams) {
    const eComPublicApi =
      await this.editorSDK.document.application.getPublicAPI(this.appToken, {
        appDefinitionId: ECOM_APP_DEF_ID,
      });
    await eComPublicApi.setThankYouPageStyleParams(params);
  }

  private async markAsEcom() {
    const checkCanMigrate = this.handleActionPayload.OOICheckCanMigrate
    if (checkCanMigrate) {
      const canMigrate = await this.editorScriptApi.canMigrateEcom();

      if (!canMigrate) {
        throw new Error('Can Migrate API return false - errorCode: 77193cfb-d2ce-49f2-9338-1555fa4e2c3b');
      }
    }

    return this.editorScriptApi.setMigratedSiteEcomCompatible();
  }

  private async removeCheckoutPage() {
    const allPages = await this.getAllSitePages();
    const bookingCheckoutPageData = allPages.find(
      (page) => page.tpaPageId === PageId.BOOKINGS_CHECKOUT,
    );

    if (bookingCheckoutPageData) {
      await this.editorSDK.document.pages.remove(this.appToken, {
        pageRef: {
          id: bookingCheckoutPageData.id,
        },
        shouldShowEditorRemovePanel: false,
      });
    }
  }

  protected get widgetId() {
    return '';
  }

  public get widgetName() {
    return 'EcomPagesMigrator';
  }
}
