import { EditorSDK } from '@wix/platform-editor-sdk';
import { EcomComponent } from '@wix/ecom-platform-sdk';
import { removeBookCheckoutPageOnEcom } from '../pages-panel-actions';
import { addBookingCheckoutPage } from '../pages-actions';
import {ecomRolloutButNotSite, getBookingsData, isEcomCartInstalled} from '../editor-sdk-actions';
import { EditorScriptApi } from '../../api/api';
import { isExperimentEnabled } from '../experiments';
import { experiments } from '../../constants';
import { updateIsCartEnabledBusinessProperty, getIsCartEnabledBusinessProperty } from '../migrate-actions';
import { openCartSuccessfullyInstalledModal } from '../migration-modals';

interface SkipEcomInstallationParams {
  isFirstInstall: boolean;
  _editorSDK: EditorSDK;
  editorScriptApi: EditorScriptApi;
  appToken?: string;
  locale?: any;
}

interface RequiredEcomComponentsParams {
  isFirstInstall: boolean;
  _editorSDK: EditorSDK;
  editorScriptApi: EditorScriptApi;
}

export const markAsEcom = async ({
  isEcomReady,
  editorScriptApi,
  editorSdk,
  appToken,
}): Promise<boolean> => {
  if (!isEcomReady) {
    const isReady = await editorScriptApi.setEcomCompatible();
    if (isReady) {
      const isEcomMigrationEnabled = await isExperimentEnabled(experiments.IS_ECOM_MIGRATION_ENABLED);

      if (!isEcomMigrationEnabled) {
        await removeBookCheckoutPageOnEcom(editorSdk, appToken, editorScriptApi);
      }
    } else {
      await addBookingCheckoutPage(editorSdk, appToken);
    }
    return isReady;
  }
};

export const skipEcomInstallationAction = (
  {
    isFirstInstall,
    _editorSDK,
    editorScriptApi,
    appToken,
    locale
  }: SkipEcomInstallationParams
) => {
  return async () => {
    const isCartEnabledFT = await isExperimentEnabled(experiments.CART_ENABLED);
    const isEcomMigrationEnabled = await isExperimentEnabled(experiments.IS_ECOM_MIGRATION_ENABLED);
    if (isEcomMigrationEnabled) {
      return true;
    }

    if (isCartEnabledFT) {
      const { instance } = await getBookingsData(_editorSDK, appToken);

      const isCartEnabled: boolean | undefined = await getIsCartEnabledBusinessProperty(editorScriptApi, instance);
      if (isFirstInstall) {
        // undefined meaning this flag never initiate and we should initiate for first install to true
        if (isCartEnabled === undefined) {
          await updateIsCartEnabledBusinessProperty(editorScriptApi, instance, true);
        }
        return false;
      }

      if (!isCartEnabled) {
        return true;
      }

      const isCartinstalled = !!(await isEcomCartInstalled(_editorSDK));

      if (!isCartinstalled) {
        await openCartSuccessfullyInstalledModal(_editorSDK, editorScriptApi, locale, instance);
      }

      return isCartinstalled;
    } else {
      if (!isFirstInstall) {
        const isEcomRolloutButNotSite = await ecomRolloutButNotSite(
          _editorSDK,
          editorScriptApi,
        );
        return !isEcomRolloutButNotSite;
      }

      return !(await editorScriptApi.canInstallEcom());
    }
  }
}

export const requiredEcomComponentsAction = (): () => Promise<EcomComponent[]> => {
  return async () => {
    const isCartEnabled = await isExperimentEnabled(experiments.CART_ENABLED);

    if (isCartEnabled) {
      return [EcomComponent.CHECKOUT, EcomComponent.THANK_YOU_PAGE, EcomComponent.CART, EcomComponent.CART_ICON];
    }
    return [EcomComponent.CHECKOUT, EcomComponent.THANK_YOU_PAGE];
  }
}

